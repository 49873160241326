import styled from 'styled-components'
import { MD_PX } from '../../header/Header.styled'
import { ButtonDS, CardDS } from '../../'
import { tokens } from '@JOTAJornalismo/jota-design-system'
const { colors, borders } = tokens
import { FC } from 'react'

/**
 * Mais Lidas Component
 * @return {React.JSX.Element}
 */
const MostReadCTAComponent: FC<{
    title: string
    description: string
    link: string
}> = ({ title, description, link }): React.JSX.Element => {
    /**
     * Retorna um redirect para o link selecionado pelo painel admin.
     * @return {redirect} p/ a URL selecionada no admin.
     */
    function handleButtonClick() {
        return window.open(link, '_blank', 'noopener,noreferrer')
    }

    return (
        <CardContainer>
            <CardDS
                size='large'
                title={title}
                newsLink={'https://www.jota.info/'}
                text={description}
                titleSearchTerm={''}
                type='bleeding'
            />
            <ButtonContainer>
                <ButtonDS
                    type='filled'
                    color={'orange'}
                    label='Saiba Mais'
                    FirstIcon='jotaMaterialMail'
                    size='medium'
                    fillIcone='jotaWhite'
                    width='100%'
                    onClick={handleButtonClick}
                />
            </ButtonContainer>
        </CardContainer>
    )
}

export default MostReadCTAComponent

const CardContainer = styled.div`
    background: ${colors.transparent.jotaTYellowLighter40};
    border-radius: ${borders.jotaRadiusDefault};
    max-width: 300px;
    box-sizing: border-box;
    max-height: 435px;
    width: 100%;

    @media only screen and (max-width: ${MD_PX}) {
        max-width: none;
        width: 100%;

        button {
            width: auto;
        }
    }
`

const ButtonContainer = styled.div`
    padding-inline: 16px;
    padding-bottom: 16px;
`

export { LogoJOTA } from './geral/LogoJOTA.component'

export {
    ButtonDS,
    InputDS,
    PaginationDS,
    TabDS,
    AccordionDS,
    TagDS,
    AvatarDS,
    ActionDS,
    DialogDS,
    CardDS,
    ProfileCardDS
} from './geral/JOTADS.components'

export { LoadingLine } from './loading/Loading.component'
export { default as PasswordRequirements } from './autentication/PasswordRequirements'
export { default as WaitingComponent } from './geral/Waiting.component'
export { default as BannerComponent } from './home/Banner.component'
export { default as Header } from './header'
export { default as HeadlineComponent } from './home/first-scroll/Headline.component'
export { default as MiddleColumnComponent } from './home/first-scroll/MiddleColumn.component'
export { default as JotaPROComponent } from './home/first-scroll/JotaPRO.component'
export { default as OpinionAndAnalysisComponent } from './home/second-scroll/OpinionAndAnalysis.component'
export { default as BannerPROComponent } from './home/second-scroll/BannerPRO.component'
export { default as MaisLidasCTAComponent } from './home/third-scroll/MostReadCTA'
export { default as MaisLidasComponent } from './home/third-scroll/MostRead'
export { default as FooterComponent } from './footer/Footer.component'
export { default as LastNewsComponent } from './home/third-scroll/LastNews'
export { default as VerticalComponent } from './home/second-scroll/Vertical.component'
export { default as FakeComponent } from './geral/Fake.component'
export { default as SeasonalBannerComponent } from './home/second-scroll/seasonalBanner.component'
export { default as Vertical } from './home/second-scroll/Vertical.component'
export { default as LiveButtonComponent } from './home/LiveButton.component'
export { default as MostReadComponent } from './home/third-scroll/MostRead'

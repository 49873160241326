import {
    JotaOutrosLabelPRO as LabelProIcon,
    JotaMaterialOpenNew as OpenNewIcon,
    JotaNewsletterApostasSemana as ApostasSemanaIcon,
    JotaNewsletterImpactoCrise as ImpactoCriseIcon,
    JotaNewsletterRelatorioEspecial as RelatorioEspecialIcon,
    JotaMaterialMicrophone as MicrofoneIcon,
    JotaNewsletterMatinal as MatinalIcon,
    JotaNewsletterDiretoLegislativo as DiretoLegislativoIcon,
    JotaNewsletterTrackingEstadual as TrackingEstadualIcon
} from '@JOTAJornalismo/jota-design-system'
import { IconsType } from '@JOTAJornalismo/jota-design-system/dist/jota-hooks/changeicons'

interface ProItems {
    id: number
    label: string
    hasSubcategory: boolean
    level: 'first' | 'second' | 'third'
    icon?: any
    isNew?: boolean
    isPro?: boolean
    LeftIcon?: any
}

export const hamburguerMenuProItems: Array<ProItems> = [
    {
        id: 0,
        label: 'MEU JOTA',
        hasSubcategory: true,
        level: 'first',
        icon: { LabelProIcon },
        isPro: true
    },
    {
        id: 1,
        label: 'Contexto',
        hasSubcategory: true,
        level: 'second'
    },

    {
        id: 3,
        label: 'Apostas da Semana',
        hasSubcategory: false,
        level: 'third',
        LeftIcon: ApostasSemanaIcon
    },
    {
        id: 4,
        label: 'Impacto nas Instituições',
        hasSubcategory: false,
        level: 'third',
        LeftIcon: ImpactoCriseIcon
    },
    {
        id: 5,
        label: 'Análise',
        hasSubcategory: true,
        level: 'second'
    },
    {
        id: 6,
        label: 'Relatórios Especiais',
        hasSubcategory: false,
        level: 'third',
        LeftIcon: RelatorioEspecialIcon
    },

    {
        id: 8,
        label: 'Áudios dos analistas',
        hasSubcategory: false,
        level: 'third',
        isNew: true,
        LeftIcon: MicrofoneIcon
    },
    {
        id: 9,
        label: 'Monitoramento',
        hasSubcategory: true,
        level: 'second'
    },

    {
        id: 11,
        label: 'Matinal',
        hasSubcategory: false,
        level: 'third',
        icon: OpenNewIcon,
        LeftIcon: MatinalIcon
    },
    {
        id: 12,
        label: 'Direto do Legislativo',
        hasSubcategory: false,
        level: 'third',
        icon: OpenNewIcon,
        LeftIcon: DiretoLegislativoIcon
    },
    {
        id: 13,
        label: 'Tracking',
        hasSubcategory: false,
        level: 'third',
        icon: OpenNewIcon,
        LeftIcon: TrackingEstadualIcon
    }
]

export type menuSampleApiI = {
    id: string
    text: string
    childs:
        | {
              id: string
              text: string
              icon: IconsType
              url: string
          }[]
        | null
    icon?: IconsType
    url?: string
    iconPos?: 'right' | 'left'
    external?: boolean
}

export const UserMenuSampleAPI: menuSampleApiI[] = [
    {
        id: '0',
        text: 'Minha Conta',
        url: `${process.env.NEXT_PUBLIC_JOTAINFO}/minha-conta/`,
        childs: [],
        icon: 'jotaMaterialOpenNew',
        iconPos: 'right',
        external: true
    },
    {
        id: '5',
        text: 'Sair',
        childs: null,
        icon: 'jotaMaterialLogout',
        iconPos: 'left',
        url: '/logout'
    }
]
export type proItemsSampleApiI = {
    id: string
    text: string
    url?: string
    rightIcon?: IconsType
    leftIcon?: IconsType
    isLinkExternal?: boolean
}

export const ProPoderItems: proItemsSampleApiI[] = [
    {
        id: '8',
        text: 'Apostas da Semana',
        url: '/poder/apostas-da-semana',
        leftIcon: 'jotaNewsletterApostasSemana',
        isLinkExternal: false
    },
    {
        id: '35',
        text: 'Impacto nas Instituições',
        url: '/poder/impacto-nas-instituicoes',
        leftIcon: 'jotaNewsletterImpactoCrise',
        isLinkExternal: false
    },
    {
        id: '37',
        text: 'Risco Político',
        url: '/poder/risco-politico',
        leftIcon: 'jotaNewsletterRiscoPolitico',
        isLinkExternal: false
    },
    {
        id: '38',
        text: 'Alertas',
        url: '/poder/alertas',
        leftIcon: 'jotaNewsletterAlertas',
        isLinkExternal: false
    }
]

export const ProTributosItems: proItemsSampleApiI[] = [
    {
        id: '90',
        text: 'Apostas da Semana',
        url: '/tributos/apostas-da-semana',
        leftIcon: 'jotaNewsletterApostasSemana',
        isLinkExternal: false
    },
    {
        id: '25',
        text: 'Direto do CARF',
        url: '/tributos/direto-do-carf',
        leftIcon: 'jotaNewsletterDiretoCarf',
        isLinkExternal: false
    },
    {
        id: '23',
        text: 'Direto da Corte',
        url: '/tributos/direto-da-corte',
        leftIcon: 'jotaNewsletterDiretoCorte',
        isLinkExternal: false
    },
    {
        id: '24',
        text: 'Direto do Legislativo',
        url: '/tributos/direto-do-legislativo',
        leftIcon: 'jotaNewsletterDiretoLegislativo',
        isLinkExternal: false
    },
    {
        id: '22',
        text: 'Matinal',
        url: '/tributos/matinal',
        leftIcon: 'jotaNewsletterMatinal',
        isLinkExternal: false
    },
    {
        id: '76',
        text: 'Relatórios Especiais',
        url: '/tributos/relatorio-especial',
        leftIcon: 'jotaNewsletterRelatorioEspecial',
        isLinkExternal: false
    }
]

export const ProPoderSaudeItems: proItemsSampleApiI[] = [
    {
        id: '12',
        text: 'Apostas da Semana',
        url: '/saude/apostas-da-semana',
        leftIcon: 'jotaNewsletterApostasSemana'
    },
    {
        id: '14',
        text: 'Bastidores da Saúde',
        url: '/saude/bastidores-da-saude',
        leftIcon: 'jotaNewsletterBastidoresSaude'
    },
    {
        id: '107',
        text: 'Direto da Anvisa/ANS',
        url: '/saude/direto-da-anvisa-ans',
        leftIcon: 'jotaNewsletterDiretoAnvisa'
    },
    {
        id: '11',
        text: 'Direto da Corte',
        url: '/saude/direto-da-corte',
        leftIcon: 'jotaNewsletterDiretoCorte'
    },
    {
        id: '13',
        text: 'Direto do Legislativo',
        url: '/saude/direto-do-legislativo',
        leftIcon: 'jotaNewsletterDiretoLegislativo'
    },
    {
        id: '9',
        text: 'Matinal',
        url: '/saude/matinal',
        leftIcon: 'jotaNewsletterMatinal'
    },
    {
        id: '77',
        text: 'Relatório Especial',
        url: '/saude/relatorio-especial',
        leftIcon: 'jotaNewsletterRelatorioEspecial'
    }
]

export const news = [
    {
        id: 1,
        title: 'Lula fala em priorizar "dívida social" ao tratar de risco fiscal',
        hat: 'impacto nas instituições',
        author: '9 de novembro de 2022',
        relatedTag: '21:10'
    },
    {
        id: 2,
        title: 'Para contrapor Lira, núcleo político de Lula flerta com partidos "independentes" na Câmara',
        hat: 'risco político',
        author: '8 de novembro de 2022',
        relatedTag: '20:36'
    },
    {
        id: 3,
        title: 'Câmara aprova PEC que altera eleições em tribunais de justiça',
        hat: 'alertas',
        author: '7 de novembro de 2022',
        relatedTag: '11:35'
    },
    {
        id: 4,
        title: 'Câmara aprova PEC que altera eleições em tribunais de justiça',
        hat: 'alertas',
        author: '7 de novembro de 2022',
        relatedTag: '11:35'
    }
]

export const painelAdm = [
    {
        id: 1,
        title: 'Amazônia: eleições vão decidir equilíbrio entre devastação e preservação',
        hat: 'Especial Eleições',
        dateTag: 'redação Jota',
        text: 'Pesquisadores acreditam que se continuarmos em processo de degradação progressivo',
        imagePath:
            'https://images.jota.info/wp-content/uploads/2021/08/gertruda-valaseviciute-xmobps6v-gy-unsplash-scaled.jpg',
        author: 'Felipe Amorim, Lucas Gomes, Bruna Borges'
    },
    {
        id: 2,
        title: "Transição de governo e os eventuais riscos de uma 'heterodoxia jurídica'",
        hat: 'Novo GOverno',
        author: 'Luciano Benetti...',
        type: 'artigos'
    },
    {
        id: 3,
        title: 'Governo de Mato Grosso do Sul institui Marco Legal do Sistema Ferroviário',
        hat: 'Coluna do Tracking',
        author: 'Valentina Trevo...',
        type: 'artigos',
        imagePath:
            'https://images.jota.info/wp-content/uploads/2023/01/danila-hamsterman-ivf9nhiutga-unsplash-1-165x124.jpg'
    }
]

import styled from 'styled-components'
import { ButtonDS, CardDS } from '../../'

import { useSelector } from 'react-redux'

import { FC } from 'react'
import { StoreType } from '../../../../api/types/store'
import { AUTH_CONSTANT } from '../../../utils/constants'
import { BREAKPOINTS } from '../../../utils/constants/layout'

import { tokens } from '@JOTAJornalismo/jota-design-system'
const { borders, colors } = tokens

/**
 *
 * Mais Lidas Component
 * @return {React.JSX.Element}
 */
const LastNewsComponent: FC<{
    title: string
    description: string
    link: string
    btn: string
}> = ({ title, description, link, btn }): React.JSX.Element => {
    const { auth } = useSelector((state: StoreType) => state)

    /**
     * Retorna um redirect para o link selecionado pelo painel admin.
     * @return {redirect} p/ a URL selecionada no admin.
     */
    function handleButtonClick() {
        return window.open(link, '_blank', 'noopener,noreferrer')
    }
    return (
        <>
            {auth?.authState != AUTH_CONSTANT.AUTH_STATE.USER_LOGGED && (
                // adicionar validação de admin está on &&
                <NewsCTAContainer>
                    <CardDS
                        size='large'
                        title={title}
                        newsLink={link}
                        text={description}
                        titleSearchTerm={''}
                        type='bleeding'
                    />
                    <ButtonContainer>
                        <ButtonDS
                            type='filled'
                            color={'orange'}
                            label={btn}
                            FirstIcon='jotaMaterialMail'
                            size='medium'
                            fillIcone='jotaWhite'
                            width='100%'
                            onClick={handleButtonClick}
                        />
                    </ButtonContainer>
                </NewsCTAContainer>
            )}
        </>
    )
}

export default LastNewsComponent

const NewsCTAContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: ${colors.transparent.jotaTCyanLighter40};
    border-radius: ${borders.jotaRadiusDefault};
    width: 300px;
    max-height: fit-content;
    margin-left: 44px;

    @media only screen and (max-width: ${BREAKPOINTS.MD_PX}) {
        max-width: none;
        width: 100%;
        margin-left: 0px;

        button {
            width: auto;
        }
    }

    @media only screen and (max-width: ${BREAKPOINTS.SM_PX}) {
        margin-top: 24px;
    }
`

const ButtonContainer = styled.div`
    padding-inline: 16px;
    padding-bottom: 16px;

    @media only screen and (max-width: ${BREAKPOINTS.SM_PX}) {
        button {
            width: 100%;
        }
    }
`

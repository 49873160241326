import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'

const JotaSingleEl = () => {
    return (
        <JotaSingle>
            <Skeleton style={{ width: '30%', marginBottom: '10px' }} />
            <Skeleton style={{ width: '90%', height: '30px' }} />
            <Skeleton style={{ width: '100%', height: '30px' }} />
            <Skeleton style={{ width: '60%', height: '30px' }} />
            <Skeleton style={{ width: '50%', marginTop: '10px' }} />
        </JotaSingle>
    )
}

const JOTAPROLoading = () => {
    return (
        <JotaSingleContainer>
            <JotaSingleEl />
            <JotaSingleEl />
            <JotaSingleEl />
        </JotaSingleContainer>
    )
}

const JotaSingleContainer = styled.div``

const JotaSingle = styled.div`
    padding: 16px;
    border-bottom: 1px solid #d3d3d966;
`

export default JOTAPROLoading

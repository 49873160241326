import { create } from 'zustand'

export const useSearchStore: any = create((set: any) => ({
    inputValue: '',
    setInputValue: (newValue: any) => {
        set({ inputValue: newValue })
    },
    filterValue: '',
    setFilterValue: (newValue: any) => {
        set({ filterValue: newValue })
    },
    orderValue: '',
    setOrderValue: (newValue: any) => {
        set({ orderValue: newValue })
    },
    pageValue: 1,
    setPageValue: (newValue: any) => {
        set({ pageValue: newValue })
    }
}))

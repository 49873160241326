import styled from 'styled-components'
import { CardDS } from '../../'
import LastNewsCTA from './LastNewsCTA'
import { FC, useEffect, useState } from 'react'
import { UltimasIH } from '../../../interfaces'
import { BREAKPOINTS } from '../../../utils/constants/layout'
import { tokens } from '@JOTAJornalismo/jota-design-system'
import { VerticaisDesc } from '../second-scroll/Vertical.component'
import { checkSpecialOrInfoNews } from '@App/libs/utils/constants/route'
import { getImageOutput } from '../first-scroll/Headline.component'
import { CONTEUDO_LIBERADO } from '../first-scroll/MiddleColumn.component'
import router from 'next/router'
const { colors, fonts, spaces } = tokens

/**
 * Mais Lidas Component
 * @return {React.JSX.Element}
 */
const LastNewsComponent: FC<{ ultimas: UltimasIH }> = ({
    ultimas
}): React.JSX.Element => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    })

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            })
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <LastNewsContainer>
            <Title>Últimas notícias do jota.info</Title>
            <VerticaisDesc>
                Os conteúdos mais recentes publicados em nosso portal de
                notícias
            </VerticaisDesc>
            <ListAndCtaContainer>
                <NewsListContainer>
                    {ultimas.news.slice(0, 4).map((newItem, index) => (
                        <div key={newItem.id + index}>
                            <CardDS
                                title={newItem.title}
                                titleSearchTerm={''}
                                newsLink={`${checkSpecialOrInfoNews(
                                    newItem.categories,
                                    newItem.slug,
                                    newItem.permalink
                                )}`}
                                tagLabel={newItem.hat}
                                tagDisableHover
                                relatedTagLabel={newItem.author}
                                text={newItem.snippet}
                                size='small'
                                image={
                                    newItem.images &&
                                    getImageOutput(newItem.images, windowSize)
                                }
                                isVertical={true}
                                subjectTagType='outline'
                                authorTagLabel={newItem?.authors[0]?.name}
                                multipleAuthors={
                                    Array.isArray(newItem.authors)
                                        ? newItem.authors
                                        : []
                                }
                                multipleAuthorsClickEvent={(authorUrl) => {
                                    if (authorUrl) {
                                        return router.push(`autor/${authorUrl}`)
                                    }
                                }}
                                subjectTagLabel={
                                    newItem.inherits_from_PRO
                                        ? CONTEUDO_LIBERADO
                                        : undefined
                                }
                                subjectTagDisableHover
                                isSponsored={newItem.inherits_from_PRO ?? false}
                            />
                        </div>
                    ))}
                </NewsListContainer>
                {ultimas.enabled && (
                    <div>
                        <LastNewsCTA
                            description={ultimas.ctaDescription}
                            link={ultimas.ctaLink}
                            title={ultimas.ctaTitle}
                            btn={ultimas.btnLabel}
                        />
                    </div>
                )}
            </ListAndCtaContainer>
        </LastNewsContainer>
    )
}

export default LastNewsComponent

const LastNewsContainer = styled.div`
    box-sizing: border-box;
    padding-inline: 44px;
    margin-block: 44px;

    @media only screen and (max-width: ${BREAKPOINTS.MD_PX}) {
        padding-inline: 36px;
    }

    @media only screen and (max-width: ${BREAKPOINTS.SM_PX}) {
        padding-inline: 16px;
    }
`
const Title = styled.h4`
    ${fonts.jotaHeading04};
    color: ${colors.black.jotaBlack};
    margin: 0;
`

const NewsListContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: ${spaces.jotaSpacing44};
    margin-top: 24px;

    @media only screen and (max-width: ${BREAKPOINTS.MD_PX}) {
        grid-template-columns: repeat(2, 1fr);
        gap: ${spaces.jotaSpacing24};
    }

    @media only screen and (max-width: ${BREAKPOINTS.SM_PX}) {
        grid-template-columns: repeat(1, 1fr);
        gap: 0;
    }
`

const ListAndCtaContainer = styled.div`
    display: flex;
    @media only screen and (max-width: ${BREAKPOINTS.MD_PX}) {
        flex-direction: column;
    }

    @media only screen and (max-width: ${BREAKPOINTS.SM_PX}) {
        flex-direction: column;
    }
`

import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { MD_PX, SM_PX } from '../../header/Header.styled'
import { CardDS } from '../../'
import MostReadCTAComponent from './MostReadCTA'
import { tokens } from '@JOTAJornalismo/jota-design-system'
const { colors, fonts } = tokens
import { FC } from 'react'
import { StoreType } from '../../../../api/types/store'
import { MaisLidasIH } from '../../../interfaces'
import { AUTH_CONSTANT } from '../../../utils/constants'
import { VerticaisDesc } from '../second-scroll/Vertical.component'
import { checkSpecialOrInfoNews } from '@App/libs/utils/constants/route'
import router from 'next/router'

/**
 * Mais Lidas Component
 * @return {React.JSX.Element}
 */
const MostReadComponent: FC<{ maisLidas: MaisLidasIH }> = ({
    maisLidas
}): React.JSX.Element => {
    const { auth } = useSelector((state: StoreType) => state)

    const quantityNews =
        auth?.authState === AUTH_CONSTANT.AUTH_STATE.USER_LOGGED ? 8 : 4

    return (
        <ComponentContainer>
            <ComponentTitle>Mais lidas do jota.info</ComponentTitle>
            <VerticaisDesc>
                Os conteúdos mais acessados da semana em nosso portal de
                notícias
            </VerticaisDesc>
            <LoggedOutComponent>
                <LoggedOutList>
                    {maisLidas?.news?.slice(0, quantityNews).map((newItem) => (
                        <ListItem key={newItem.id}>
                            <CardDS
                                title={newItem.title}
                                titleSearchTerm={''}
                                newsLink={`${checkSpecialOrInfoNews(
                                    newItem.categories,
                                    newItem.slug,
                                    newItem.link
                                )}`}
                                tagLabel={newItem.hat}
                                tagDisableHover
                                dateTagLabel={newItem.author}
                                size='small'
                                authorTagLabel={' '}
                                multipleAuthors={
                                    Array.isArray(newItem.authors)
                                        ? newItem.authors
                                        : []
                                }
                                multipleAuthorsClickEvent={(authorUrl) => {
                                    if (authorUrl) {
                                        return router.push(`autor/${authorUrl}`)
                                    }
                                }}
                            />
                        </ListItem>
                    ))}
                </LoggedOutList>

                {maisLidas.enable && (
                    <MostReadCTAComponent
                        description={maisLidas.description}
                        link={maisLidas.link}
                        title={maisLidas.title}
                    />
                )}
            </LoggedOutComponent>
        </ComponentContainer>
    )
}

export default MostReadComponent

const ComponentTitle = styled.h4`
    ${fonts.jotaHeading04};
    color: ${colors.black.jotaBlack};
    margin: 0;
`

const ComponentContainer = styled.div`
    box-sizing: border-box;
    padding-inline: 44px;
    padding-block: 44px;

    @media only screen and (max-width: ${MD_PX}) {
        padding-inline: 36px;
    }

    @media only screen and (max-width: ${SM_PX}) {
        padding-inline: 16px;
    }
`

const LoggedOutComponent = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: flex-start;
    justify-content: space-between;
    gap: 44px;
    margin-top: 24px;

    @media only screen and (max-width: ${MD_PX}) {
        flex-direction: column;
        gap: 20px;
    }
`

const LoggedOutList = styled.ol`
    display: grid;
    grid-template-columns: repeat(2, auto);
    padding: 0;
    gap: 20px 44px;
    margin: 0;

    @media only screen and (max-width: ${MD_PX}) {
        display: flex;
        flex-direction: column;
    }
`

const ListItem = styled.li`
    display: flex;
    list-style: none;
    color: ${colors.orange.jotaOrangeLighter};
    gap: 16px;

    ::before {
        content: counter(list-item);
        counter-increment: list-item;
        color: ${colors.orange.jotaOrangeLighter};
        ${fonts.jotaSerifHeading02};
        line-height: 2.25rem;
    }
`

import { useEffect, useState } from 'react'

/**
 * useMediaquery
 * @param {string} query
 * @return {boolean}
 */
function useMediaQuery(query: string): boolean {
    const getMatches = (query: string): boolean => {
        // Prevents SSR issues
        if (
            query != '' &&
            typeof window !== 'undefined' &&
            window.matchMedia(query) &&
            typeof window.matchMedia !== 'undefined'
        ) {
            return window.matchMedia(query).matches
        }
        return false
    }

    const [matches, setMatches] = useState<boolean>(false)

    useEffect(() => {
        getMatches(query)
    }, [query])

    useEffect(() => {
        /**
         * Handle Change
         */
        function handleChange() {
            if (typeof window !== 'undefined') {
                setMatches(getMatches(query))
            }
        }

        if (typeof window != 'undefined') {
            const matchMedia = window.matchMedia(query)

            // Triggered at the first client-side load and if query changes
            handleChange()

            // Listen matchMedia
            if (matchMedia.addListener) {
                matchMedia.addListener(handleChange)
            } else {
                matchMedia.addEventListener('change', handleChange)
            }

            return () => {
                if (matchMedia.removeListener) {
                    matchMedia.removeListener(handleChange)
                } else {
                    matchMedia.removeEventListener('change', handleChange)
                }
            }
        }
    }, [query])

    return matches
}

export default useMediaQuery
